<template>
<ve-form ref="searchForm">
	<div class="row">
		<ve-col class="mb-3" xs12 md12>
		<ve-form-group>
			<label>{{__("brreg_search")}}</label>
			<ve-brreg rules="required" @on-select="updateCompany" :placeholder="__('brreg_placeholder')"></ve-brreg>			
		</ve-form-group>
		</ve-col>
	</div>

	<div class="row" v-if="company.name && company.org_no">	
		<ve-col class="mb-3" xs12 md6>
		<ve-form-group>
			<label>{{__("company_name")}}</label>
			<ve-input v-model="company.name" rules="required"></ve-input>
		</ve-form-group>
		</ve-col>
		<ve-col class="mb-3" xs12 md6>
		<ve-form-group>
			<label>{{ __("org_no")}}</label>
			<ve-input v-model="company.org_no" rules="required|digit"></ve-input>
		</ve-form-group>
		</ve-col>
	</div>
	
	<div class="row"
	     v-if="status.brreg.active !== true">
		<button type="button"
		        class="btn btn-rounded btn-primary mx-auto mb-2 animated icon"
		        :disabled="!company.name || !company.org_no"
		        @click="fetchData"
		>{{__("fetch_data")}}
<!--			<span v-html="$root.octicons.search.toSVG({ class: 'octicon-primary', height: 16, width: 16 })"></span>-->
			<span><img src="../../../public/search.png" style="width: 16px; height: 16px;margin-left: -1.5rem"></span>
		</button>
	</div>
	
	<div class="text-center my-1"
	     v-show="sameCompany">
		<small>{{ __('same_company') }}</small>
	</div>
	
	<!-- BrReg -->
	<div class="search-status shadow-sm"
	     :class="{ active: status.brreg.active }">
		
		<img src="//app.verified.eu/lib/iframes/common/assets/loader64.gif"/>
		<img src="//app.verified.eu/lib/iframes/common/assets/ve_v.png"
		     class="status-complete"
		     :style="{ opacity: status.brreg.task === 'completed' ? 1 : 0 }"/>
		
		<div class="status-text"
		     :style="{ opacity: status.brreg.task === 'searching' ? 1 : 0 }">{{__("fetchingFromBrreg")}}
		</div>
		<div class="status-text"
		     :style="{ opacity: status.brreg.task === 'completed' ? 1 : 0 }">{{__("fetchedFromBrreg")}}
		</div>
	</div>
	
	<!-- ID-Rights -->
	<div class="search-status shadow-sm delay"
	     :class="{ active: status.idrights.active }">
		
		<img src="//app.verified.eu/lib/iframes/common/assets/loader64.gif"/>
		<img src="//app.verified.eu/lib/iframes/common/assets/ve_v.png"
		     class="status-complete"
		     :style="{ opacity: status.idrights.task === 'completed' ? 1 : 0 }"/>
		
		
		<div class="status-text"
		     :style="{ opacity: status.idrights.task === 'searching' ? 1 : 0 }">{{__("fetchingFromUnitReg")}}
		</div>
		<div class="status-text"
		     :style="{ opacity: status.idrights.task === 'completed' ? 1 : 0 }">{{__("fetchedFromUnitReg")}}
		</div>
	</div>
	
	<!-- Result -->
	<div class="search-status result shadow-sm delay"
	     :class="{ active: status.result.active, show: status.result.task === 'completed', opened: status.result.opened }">
		
		<img src="//app.verified.eu/lib/iframes/common/assets/loader64.gif"/>
		<img src="//app.verified.eu/lib/iframes/common/assets/ve_v.png"
		     class="status-complete"
		     :style="{ opacity: status.result.task === 'completed' ? 1 : 0 }"/>
		
		
		<div class="status-text"
		     :style="{ opacity: status.result.task === 'processing' ? 1 : 0 }">{{__("compilingResult")}}
		</div>
		<div class="status-text status-text-result"
		     :style="{ opacity: status.result.task === 'completed' ? 1 : 0 }">{{__("result")}}
		</div>
		
		<div class="result-body"
		     :class="{ opened: status.result.opened }">
			<table class="result-table">
				<tr>
					<td>{{ __("company_name") }}:</td>
					<td>{{ this.result.name }}</td>
				</tr>
				<tr>
					<td>{{ __("org_no") }}:</td>
					<td>{{ this.result.org_no }}</td>
				</tr>
				<tr>
					<td>{{ __("postal_address") }}:</td>
					<td>{{ this.result.address }}</td>
				</tr>
				<tr>
					<td>{{ __("postal_town") }}:</td>
					<td>{{ this.result.zip + " " + this.result.town }}</td>
				</tr>
				<tr>
					<td>{{ __("country") }}:</td>
					<td>{{ this.result.country }}</td>
				</tr>
				<tr>
					<td>{{ __("business") }}:</td>
					<td>{{ this.result.business + " (" + this.result.businessCode + ")" }}</td>
				</tr>
				<tr>
					<td>{{ __("mvaRegistry") }}</td>
					<td>{{ __(this.result.inMvaReg ? "yes" : "no") }}</td>
				</tr>
				<tr>
					<td>{{ __("businessRegistry") }}</td>
					<td>{{ __(this.result.inBusinessReg ? "yes" : "no") }}</td>
				</tr>
				<tr>
					<td>{{ __("bankrupt") }}</td>
					<td>{{ __(this.result.bankrupt ? "yes" : "no") }}</td>
				</tr>
				<tr>
					<td>{{ __("liquidation") }}</td>
					<td>{{ __(this.result.liquidation ? "yes" : "no") }}</td>
				</tr>
				<tr>
					<td>{{ __("compulsoryLiquidation") }}</td>
					<td>{{ __(this.result.compulsoryLiquidation ? "yes" : "no") }}</td>
				</tr>
			</table>
			
			<h5 class="text-center mt-5 mb-3"
			    v-if="result.roles">{{ __("roles") }}</h5>
			
			<table class="result-table roles"
			       v-if="result.roles">
				<tr v-for="(role, i) in result.roles"
				    :key="i">
					<td>{{ role.Person && role.Person.Name && (role.Person.Name.LastName.toLowerCase() + ", " +
						role.Person.Name.FirstName.toLowerCase()) }}:
					</td>
					<td>{{ role.BusinessPosition && role.BusinessPosition.Description }}</td>
				</tr>
			</table>
			
			<div v-if="result.aml">
				
				<h5 class="text-center mt-5 mb-3">{{ __("officialRoles") }}</h5>
				
				<table class="result-table aml"
				       v-if="(result.aml.officialRoles.ceo&&result.aml.officialRoles.ceo.name)
				       || (result.aml.officialRoles.chair&&result.aml.officialRoles.chair.name)
				       || result.aml.officialRoles.boardMembers.length
				       || result.aml.officialRoles.deputyBoardMembers.length">
					<tr>
						<th>{{ __("name")}}</th>
						<th>{{ __("dateOfBirth")}}</th>
						<th>{{ __("role")}}</th>
					</tr>
					<tr v-if="result.aml.officialRoles.ceo && result.aml.officialRoles.ceo.name">
						<td>{{ result.aml.officialRoles.ceo.name }}</td>
						<td>{{ result.aml.officialRoles.ceo.dateOfBirth }}</td>
						<td>{{ __("ceo")}}</td>
					</tr>
					<tr v-if="result.aml.officialRoles.chair && result.aml.officialRoles.chair.name">
						<td>{{ result.aml.officialRoles.chair.name }}</td>
						<td>{{ result.aml.officialRoles.chair.dateOfBirth }}</td>
						<td>{{ __("chair")}}</td>
					</tr>
					<tr v-for="(bm, i) in result.aml.officialRoles.boardMembers"
					    :key="`bm-${i}`">
						<td>{{ bm.name }}</td>
						<td>{{ bm.dateOfBirth }}</td>
						<td>{{ __("boardMembers")}}</td>
					</tr>
					<tr v-for="(dm, i) in result.aml.officialRoles.deputyBoardMembers"
					    :key="`dm-${i}`">
						<td>{{ dm.name }}</td>
						<td>{{ dm.dateOfBirth }}</td>
						<td>{{ __("deputyBoardMembers")}}</td>
					</tr>
				</table>
				
				<p class="text-center" v-else>{{ __("amlReturnNull") }}</p>
				
				<!-- RRH -->
				<h5 class="text-center mt-5 mb-3">{{ __("owners_info") }}</h5>
				
				<span v-if="result.aml.ownership.beneficial.length||result.aml.ownership.shareholders.length">
					<table class="result-table aml center">
						<tr>
							<th>{{ __("type")}}</th>
							<th>{{ __("name")}}</th>
							<th>{{ __("owner_share")}}</th>
						</tr>
						<tr v-for="(be, i) in result.aml.ownership.beneficial"
						    :key="`be-${i}`">
							<td>{{ __("physical_person")}} {{ i+1 }}</td>
							<td>{{ be.name }}</td>
							<td>{{ be.percentageControl }}</td>
						</tr>
						<tr v-for="(sh, i) in result.aml.ownership.shareholders"
						    :key="`sh-${i}`">
							<td>{{ __("judicial_person")}} {{ i+1 }}</td>
							<td>{{ sh.name }}</td>
							<td>{{ sh.percentage }}</td>
						</tr>
					</table>
				</span>
				
				<span v-else>
					<p>{{ __("amlReturnNull") }}</p>
				</span>
				
				<!-- RRH -->
				<h5 class="text-center mt-5 mb-3">{{ __("PEP_info") }}</h5>
				
				<p class="text-center"
				   v-if="result.aml.sanction && result.aml.sanction.status !== 'NONE'">{{ __("PEP_info_found") }}</p>
				<p class="text-center"
				   v-else-if="result.aml.sanction && result.aml.sanction.status === 'NONE'">{{ __("PEP_info_empty") }}</p>
				<p class="text-center"
				   v-else>{{ __("amlReturnNull") }}</p>
			</div>
		</div>
	</div>
</ve-form>
</template>

<script>
	import Vue from 'vue'
	import { idrights, oidc } from '@verified/libvf'
	import Brreg from '../brreg/brreg'
	
	export default {
		name: "ve-search",
		data() {
			return {
				company: {
					name: "",
					org_no: ""
				},
				status: {
					brreg: {
						active: false,
						task: "searching"
					},
					idrights: {
						active: false,
						task: "searching"
					},
					result: {
						active: false,
						task: "processing",
						opened: false
					}
				},
				temp: {},
				sameCompany: false,
				result: {}
			}
		},
		methods: {
			updateCompany(d) {
				this.company.name = d.navn
				this.company.org_no = d.organisasjonsnummer.toString()
				if(d.forretningsadresse) {
					if(d.forretningsadresse.adresse[0]) {
						this.company.address = ""
						for(var a in d.forretningsadresse.adresse) {
							if(Number(a) === d.forretningsadresse.adresse.length - 1) this.company.address += d.forretningsadresse.adresse[a]
							else this.company.address += d.forretningsadresse.adresse[a] + ", "
						}
					} else 
						this.company.address = d.forretningsadresse.adresse

					this.company.zip = d.forretningsadresse.postnummer
					this.company.town = d.forretningsadresse.poststed
					this.company.country = d.forretningsadresse.land;
				}
				if(d.naeringskode1) { //todo fetch all codes
					this.company.business = d.naeringskode1.beskrivelse
					this.company.businessCode = d.naeringskode1.kode
				}
				this.company.inBrReg = true
				this.company.inBusinessReg = d.registrertIForetaksregisteret
				this.company.inMvaReg = d.registrertIMvaregisteret
				this.company.bankrupt = d.konkurs
				this.company.liquidation = d.underAvvikling
				this.company.compulsoryLiquidation = d.underTvangsavviklingEllerTvangsopplosning
				this.company.orgForm = d.organisasjonsform
			},
			async fetchData() {
				if(!this.$refs.searchForm.validate())
					return;
				
				this.$emit("search", false)
				
				if(this.company.name !== this.temp.prevOrgName || this.company.org_no !== this.temp.prevOrgNr) {
					this.sameCompany = false
					this.temp.prevOrgName = this.company.name
					this.temp.prevOrgNr = this.company.org_no
				} else {
					this.sameCompany = true
					return
				}
				
				this.status.result.active = false
				this.status.idrights.active = false
				this.status.brreg.active = true
				
				// Wait for transition delay
				setTimeout(() => {
					this.status.result.opened = false
					this.status.result.task = "processing"
					this.status.idrights.task = "searching"
					this.status.brreg.task = "searching"
				}, 500)
				
				//Complete Brreg after 1.5 sec
				setTimeout(async() => {
					this.status.brreg.task = "completed"
					this.status.idrights.active = true
					
					// At least 1.5 sec delay before completing ID-Rights
					let loaded = -1, fire = () => {
						// this.company.roles = roles.CompanyRoles && roles.CompanyRoles.CompanyRole
						this.company.aml = amlData
						this.result = { ...this.company }
						this.status.idrights.task = "completed"
						this.status.result.active = true
						
						//Complete result after 1 sec
						setTimeout(() => {
							this.status.result.task = "completed"
							this.$emit("update", this.result)
							
							//Enable scrollbar for 3+ roles
							setTimeout(() => {
								this.status.result.opened = true
							}, 1000)
						}, 1500)
					}
					setTimeout(() => { if(++loaded) fire() }, 1500)
					// const roles = await idrights.getCompanyRoles(this.company.org_no.replace(/\s/g, ""))
					const amlData = await oidc.norway.getCompanyInfo(this.company.org_no, ["ownership.beneficial", "ownership.shareholders", "authorizations", "sanction", "officialRoles"])
					if(++loaded) fire()
				}, 1500)
			}
		},
		async created() {
			Vue.use(Brreg)
		}
	}
</script>