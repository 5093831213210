export const validateUppercase = (value, constraint) => {

    if(value.toUpperCase() === value) return { status: true }

    return { status: false, key: "validation.uppercase" }

}
export const validateFullname = (value, constraint) => {

    if(value.indexOf(' ') !== -1) return { status: true }

    return { status: false, key: "validation.fullname" }

}