<template>
    <div>
        <textarea ref="field" @input="handleInput" :value="value" v-mask="mask" :placeholder="placeholder" class="form-control"></textarea>
        <div v-if="!validation.status" class="invalid-feedback">
            {{ __(validation.key, validation.args) }}
        </div>
    </div>
</template>

<script>
import { validate } from "@verified/veform/src/validations"

export default {
    name: "ve-textarea",
    props: {
        value: [String, Number],
        rules: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        mask: {
            type: null,
            default: ""
        }
    },
    data() {
        return {
            validation: { status: true }
        }
    },
    methods: {
        handleInput(e) {

            this.performValidation(e.srcElement.value)

            this.$emit('input', e.srcElement.value)

        },

        performValidation(value) {
            
            this.validation = validate(value, this.rules)

            if(this.validation.status) {
                this.$refs.field.setCustomValidity("") 
            }
            else {
                this.$refs.field.setCustomValidity(this.validation.message)
            }
                
        },

    },
    watch: {
        value: {
            deep: true,
            handler(val) {
                this.performValidation(val)
            }
        }
    },
    mounted() {
        this.performValidation(this.value)
    }
}
</script>