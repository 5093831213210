<template>
<div>
	<input class="form-control"
	       ref="field"
	       v-mask="mask"
	       :value="value"
	       :placeholder="placeholder"
	       :readonly="readonly"
	       :disabled="disabled"
	       @input="handleInput">
	
	<div class="invalid-feedback"
	     v-if="!validation.status">
		{{ __(validation.key, validation.args) }}
	</div>
</div>
</template>

<script>
	import { validate } from "../../../node_modules/@verified/veform/src/validations"
	import axios from 'axios'
	
	export default {
		name: "ve-brreg-org",
		props: {
			value: [String, Number],
			placeholder: {
				type: String,
				default: ""
			},
			mask: {
				type: null,
				default: ""
			},
			readonly: {
				type: Boolean,
				default: false
			},
			disabled: {
				type: Boolean,
				default: false
			},
			rules: {
				type: String,
				default: ""
			}
		},
		data() {
			return {
				brreg: "https://data.brreg.no/enhetsregisteret/",
				brreg_all_data: {},
				brreg_list: [],
				validation: { status: true },
				exceptions: []
			}
		},
		methods: {
			handleInput(e) {
				if(!this.$root.searchOngoing) {
					
					let val = e.srcElement.value
					if(!val) return
					
					val = val.toString().replace(/[.\s]/g, "")
					if(val.length !== 9) return
					
					this.performValidation(val)
					
					if(this.checkNr(val)) {
						this.search(val)
						this.$emit('input', e.srcElement.value)
						this.performValidation(e.srcElement.value)
					} else this.$refs.field.setCustomValidity("Invalid org no")
				} else {
					this.$root.searchOngoing = false
					this.performValidation(e.srcElement.value)
				}
			},
			mod11(input) {
				let control = 2, sum = 0;
				for(let i = input.length - 2; i >= 0; --i) {
					sum += input.charAt(i) * control;
					if(++control > 7) control = 2;
				}
				let result = (11 - sum % 11);
				return result === 11 ? 0 : result;
			},
			checkNr(nr) {
				if(!nr) return false
				let check = parseInt(nr.charAt(nr.length - 1), 10)
				return check === this.mod11(nr)
			},
			async search(value) {
				let company = await this.searchNr(value)
				if(company) {
					this.update(company)
					this.$refs.field.setCustomValidity("")
				} else
					this.$refs.field.setCustomValidity("Search didn't return any companies")
			},
			handleSelect(value) {
				for(let name in this.brreg_all_data)
					if(value === name) {
						this.update(this.brreg_all_data[name])
						return
					}
			},
			performValidation(value) {
				
				this.validation = validate(value, this.rules)
				
				if(this.validation.status) {
					this.$refs.field.setCustomValidity("")
				} else {
					this.$refs.field.setCustomValidity(this.validation.message)
				}
			},
			async searchNr(nr) {
				let link = this.brreg + "enhet/" + nr + ".json"
				
				try {
					const [brreg] = await Promise.all([
						axios.get(link)
					])
					
					return brreg.data
					
				} catch(e) {
					this.exceptions.push(new Error("Unable to get brreg data. Try refreshing your browser."))
				}
			},
			update(company) {
				if(!company) return;
				this.$root.searchOngoing = true
				this.$emit('update', company)
			}
		},
		watch: {
			value: {
				deep: true,
				handler(val) {
					this.performValidation(val)
				}
			}
		},
		mounted() {
			this.performValidation(this.value)
		}
	}

</script>
