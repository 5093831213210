<template>
<div style="min-width: fit-content">
	
	<div class="loader smooth"
	     :style="{ opacity: loading ? 1 : 0 }">
		<img src="//app.verified.eu/lib/iframes/common/assets/loader64.gif"/>
	</div>
	
	<ve-login :logosrc="__config('logo')"
	          :logowidth="__config('logo_width')"
			  :authorizedCompanies="['r1MZtfWW7', 'SJLLD-czN']"
	          @login="login"
	          @exception="exception"
	          v-show="role!=='editor'&&!user">></ve-login>
	
	<div :style="{ opacity: opacity }">
		<nav class="navbar smooth"  v-if="role==='owner'&&user||role==='editor'">
			<div class="container">
				<ve-col>
					<div class="navbar-left">
						
						
						<img v-if="role==='owner'" class="logo"
							alt="Logo"
							:class="form ? 'clickable' : ''"
							:src="__config('logo') || '//app.verified.eu/lib/iframes/common/assets/logo-small-white.png'"
							:title="__config('logo') && __config('title')"
							@click="goToPortal">

						<img v-if="role==='editor'" class="logo"
							alt="Logo"
							:src="__config('logo') || '//app.verified.eu/lib/iframes/common/assets/logo-small-white.png'"
							:title="__config('logo') && __config('title')">
						<!--
	
						<img class="logo"
						     alt="Logo"
						     :src="__config('logo') || '//app.verified.eu/lib/iframes/common/assets/logo-small-white.png'"
						     :title="__config('logo') && __config('title')"
						     v-if="(__config('logo') && !__config('redirect_url')) || (!__config('logo') && __config('redirect_url'))">
						<a target="_blank"
						   :href="__config('redirect_url') || '//verified.eu/'"
						   :title="__config('logo') ? __config('title') : 'Verified'"
						   @keydown.space="openUrl(__config('redirect_url') || '//verified.eu/')"
						   v-else>
							<img class="logo"
							     alt="Logo"
							     :src="__config('logo') || '//app.verified.eu/lib/iframes/common/assets/logo-small-white.png'">
						</a>-->
						
						<span style="cursor: default" v-if="!form&&role=='owner'">{{__config('portal_title')}}</span>
						<span style="cursor: default" v-if="!form&&role=='editor'">{{__config('editor_onboarding_new_title')}}</span>
						<span style="cursor: default" v-if="form==='form onboarding_new'">{{__config('onboarding_new_title')}}</span>
						<span style="cursor: default" v-if="form==='form search'">{{__config('search_title')}}</span>
					</div>
				</ve-col>
				
				<ve-col>
					<div class="navbar-right">
						
						<span class="languages">
							<i class="flag"
							   :class="[$root.locale]"></i>
							
							<span class="selector">
								<i class="flag select"
								   tabindex="0"
								   :class="[lang]"
								   @click="langTo(lang)"
								   @keydown.enter.space="langTo(lang)"
								   v-for="(enabled, lang) in configuration['enabled_languages']"
								   :key="lang"
								   v-if="enabled === 'yes' && lang !== $root.locale"></i>
							</span>
						</span>
						<!--
						<span class="item"
						      title="Open dashboard"
						      tabindex="0"
						      @click="openUrl('//app.verified.eu/#/dashboard?companyId=' + user.namespace.split('/').pop())"
						      @keydown.enter.space="openUrl('//app.verified.eu/#/dashboard?companyId=' + user.namespace.split('/').pop())">
							<span>{{user.givenName}}</span>
							<br>
							<span class="company">{{user.company}}</span>
						</span>
						
						<img class="item"
						     src="//app.verified.eu/lib/iframes/common/assets/logout.png"
						     alt="Sign out"
						     title="Sign out"
						     tabindex="0"
						     @click="logout()"
						     @keydown.enter.space="logout()"/>-->

						<div class="dropdown" style="line-height: 1.2 !important;" v-if="role==='owner'">
							<a ref="field" id="account-dropdown" tabindex="0" class="account-field" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" readonly="true" 
								@click="toggleAccountSettings" @blur="toggleAccountSettings">
								<span>{{user.givenName}} {{user.familyName}}</span><br>
								<span class="current-company">{{user.company}}</span>
								<span class="toggle"
									:class="{ toggled: accountSettingsExpanded }">
								</span>
							</a>

							<div class="dropdown-menu account-menu">
								<span @click="openUrl('//app.verified.eu/#/dashboard?access_token=' + user.token + '&companyId=' + user.namespace.split('/').pop())" class="dropdown-item account-item">{{__("dashboard")}}</span>
								<span @click="openUrl('//app.verified.eu/#/archive?access_token=' + user.token + '&companyId=' + user.namespace.split('/').pop())" class="dropdown-item account-item">{{__("archives")}}</span>
								<div class="dropdown-divider"></div>
								<span @click="logout()" class="dropdown-item account-item">{{__("logout")}}</span>
							</div>
						</div>

					</div>
				</ve-col>
			</div>
		</nav>
		
		<div class="container-fluid smooth px-4 pt-4"
		     :class="{ 'hide-content': content.hidden }"
		     v-if="!content.disabled">

			<div class="row space-around mb-4">
				<ve-col xs12
				        md9
				        lg12
				        xl9>
						
					<div class="row space-around">
						<ve-col
							xs12
							md10
							lg11>
							<div v-if="success" class="alert alert-success alert-dismissible" style="display:flex;">
								<a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
								<!--<div v-html="$root.octicons.mail.toSVG({ class: 'octicon-mail', height: 22, width: 22 })"></div>-->
								<div class="iconify" data-icon="ic:baseline-mail-outline" data-inline="false" style="margin-top:3px; font-size: 22px; color: #1b6a11;"></div>
								<div style="margin-top:5px;margin-left:25px;font-weight:bold">{{__("envelope_success_1")}} {{successInfo}} {{__("envelope_success_2")}}</div>
							</div>
						</ve-col>
					</div>

					<div class="row space-around">
						<ve-col class="mb-4"
						        xs12
						        md10
						        lg5>
							<div class="card border-0 shadow-sm">
								<div class="card-body pb-0 messageBoard"
								     :class="{ expanded: messageBoardExpanded }">
									<h6>{{__("messages")}}</h6>
									
									<hr>
									
									<div class="row msg"
									     :tabindex="messageBoardExpanded || i < 3 ? 0 : -1"
									     :class="{ opened: msg.opened, 'always-visible': i < 3 }"
									     :title="msg.opened ? 'Click to collapse' : 'Click to expand'"
									     @click="msg.opened = !msg.opened"
									     @keydown.enter.space="msg.opened = !msg.opened"
									     v-for="(msg, i) in messageBoard"
									     :key="msg.key">
										
										<ve-col class="stamp"
										        xs3>
											<div>{{msg.value && msg.value.split(" ")[0] || "&nbsp;"}}</div>
											<div class="time">{{msg.value && msg.value.split(" ")[1] || "&nbsp;"}}</div>
										</ve-col>
										
										<ve-col class="text"
										        xs9>{{msg[$root.locale]}}
										</ve-col>
									</div>
								</div>
								
								<div class="toggle"
								     tabindex="0"
								     :class="{ toggled: messageBoardExpanded }"
								     @click="toggleMessageBoard"
								     @keydown.enter.space="toggleMessageBoard"></div>
							</div>
						</ve-col>
						
						<ve-col class="mb-4"
						        xs12
						        md10
						        lg5>
							<div class="card border-0 shadow-sm">
								<div class="card-body pb-0">
									<h6>{{__("forms")}}</h6>
									
									<hr>
									
									<div class="row mx-1">
										<button type="button"
										        style="width: 100%"
										        class="btn btn-rounded btn-primary mb-4 animated link"
										        @click="selectForm(link.value)"
										        v-for="(link, i) in links"
										        :key="i"
										>{{link[$root.locale]}}
										</button>
									</div>
								</div>
							</div>
						</ve-col>
					</div>
				</ve-col>
			</div>
		</div>
	</div>
</div>
</template>

<script>
	import axios from 'axios'
	import Vue from 'vue'
	import Login from '../login/login'
	import VueCookies from 'vue-cookies'
	
	export default {
		name: "ve-portal",
		props: {
			sheetname: {
				type: String
			},
			config: {
				type: Object,
				default: function() { return {} }
			},
			translations: {
				type: Object,
				default: function() { return {} }
			},
			lang: {
				type: String
			},
			navbarOnly: {
				type: [ Boolean, Number ],
				default: false
			}
		},
		data() {
			return {
				opacity: 0,
				loading: false,
				success: false,
				configuration: {},
				exceptions: [],
				links: [],
				messageBoard: [],
				messageBoardExpanded: false,
				accountSettingsExpanded: false,
				content: {
					hidden: false,
					disabled: false
				},
				user: null,
				role: undefined,
				form: undefined
			}
		},
		watch: {
			exceptions: function(newVal) {
				if(newVal.length)
					this.$emit('exception', newVal[newVal.length - 1])
			},
			navbarOnly: function(newVal) {
				if(!this.loading)
					if(newVal) {
						this.content.hidden = newVal
						setTimeout(() => {
							this.content.disabled = newVal
						}, 500)
					} else {
						this.form = undefined
						this.content.disabled = newVal
						setTimeout(() => {
							this.content.hidden = newVal
						}, 1)
					}
			},
			lang: function(newVal) {
				if(newVal)
					this.langTo(newVal)
			}
		},
		methods: {
			// Return translation for given key
			__(key) {
				if (this.translations[key])
					return this.translations[key][this.$root.locale]
				return '...'
			},
			// Return config label for given key and language, or value if no label
			__config(key) {
				if(this.configuration[key])
					if(this.configuration[key][this.$root.locale])
						return this.configuration[key][this.$root.locale]
					else
						return this.configuration[key].value
			},
			getURL() {
				var qp = undefined

				if (location.search.substring(1, 2) == "&") {
					qp = location.search.substring(0, 1) + location.search.substring(2, location.search.length);
					qp = JSON.parse("{\"" + decodeURI(qp.substr(1)).replace(/"/g, "\\\"").replace(/&/g, "\",\"").replace(/=/g, "\":\"") + "\"}");
				} else
					qp = JSON.parse("{\"" + decodeURI(location.search.substr(1)).replace(/"/g, "\\\"").replace(/&/g, "\",\"").replace(/=/g, "\":\"") + "\"}");

				return qp
			},
			openUrl(url) {
				window.open(url)
			},
			login(el) {
				console.log("User:", el)

				if(el !== "editor") {
					this.user = el
					this.role = this.user.role
					this.$emit("init", this.user)
					//this.$emit("setNavbarOnly", false) //this.content.hidden = this.content.disabled = this.navbarOnly = false
					setTimeout(() => {
						this.opacity = 1
					}, 10)
				} else {
					this.role = el
					//this.selectForm("form onboarding_new")
					this.$emit("init", el)
					setTimeout(() => {
						this.opacity = 1
					}, 10)
				}

				this.loading = false
			},
			logout() {
				$cookies.remove("user")
				$cookies.remove("token")
				location.reload()
			},
			goToPortal() {
				if(this.form) 
					this.selectForm('form select')
			},
			selectForm(value) {
				this.success = false
				this.form = value
				console.log(this.form)
				this.$forceUpdate()
				this.$emit('formSelected', this.form)
			},
			exception(e) {
				this.exceptions.push(e)
			},
			toggleMessageBoard() {
				if(this.messageBoardExpanded)
					setTimeout(() => {
						this.messageBoard.forEach((el, i) => {
							if(i > 2)
								el.opened = false
						})
					}, 500)
				
				this.messageBoardExpanded = !this.messageBoardExpanded
			},
			toggleAccountSettings() {
				let atr = document.getElementById("account-dropdown").attributes
				
				for(var i = 0; i < atr.length; i++){
					if(atr[i].name === "aria-expanded") {
						this.accountSettingsExpanded = (atr[i].value === "true") ? false : true
						this.$forceUpdate()
						return
					}
				}
				//this.accountSettingsExpanded = !this.accountSettingsExpanded
			},
			langTo(lang) {
				this.$root.locale = lang
				this.$forceUpdate()
				this.$emit("language",this.$root.locale)
			}
		},
		async created() {
			Vue.use(VueCookies)
			Vue.use(Login)
			
			this.$root.locale = this.$root.locale || "nb_NO"
			this.configuration = this.config
			this.content.hidden = this.content.disabled = this.navbarOnly

			//Get sheets data
			try {
				if(this.sheetname) {
					let [ config, msgs, trans ] = await Promise.all([
						axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/config'),
						axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/message-board'),
						axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/portal-translations')
					])
					
					this.configuration = { ...config.data, ...msgs.data }
				}
			} catch(e) {
				this.exceptions.push(new Error("Portal unable to get sheet data. Try refreshing your browser"))
				
				console.warn("Couldn't find sheet named '" + this.sheetname + "'")
				if(this.configuration.title)
					console.log("Using passed config:", this.configuration)
				else
					console.warn("No config passed, using defaults")
			}
			
			//Init portal lists from config
			for(let el in this.configuration)
				if(el.indexOf("message") !== -1)
					this.messageBoard.push({ ...this.configuration[el], opened: false, key: el })
				else if(el.indexOf("link") !== -1)
					this.links.push(this.configuration[el])
			this.messageBoard.reverse();

			if($cookies.get("success")) {
				this.successInfo = $cookies.get("envelope")
				this.success = true
				$cookies.remove("success")
				$cookies.remove("envelope")
			}
		}
	};
</script>