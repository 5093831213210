<template>
<div class="smooth"
     :style="{ opacity: loggedIn ? 0 : 1 }">
	<div class="loader"
	     :style="{ opacity: loading ? 1 : 0 }">
		<img src="//app.verified.eu/lib/iframes/common/assets/loader64.gif"/>
	</div>
	
	<div class="container main veLogin delay"
	     :style="{ opacity: loading ? 0 : 1 }">
		<div class="row">
			<img class="login-logo"
			     :width="logowidth || '200px'"
			     :src="logosrc || './logo.png'"/>
		</div>
		<div class="row">
			<ve-col xs1 sm3></ve-col>
			<ve-col xs10 sm6>
				<div class="headerBar"></div>
				<div class="card border-0 shadow-sm center-card noround-top mb-5">
					<div class="card-body pb-0" v-if="validCompanies.length < 2">
						<ve-form ref="loginForm"
						         @submit.prevent>
							<div class="row">
								<ve-col xs12
								        sm12>
									<ve-form-group>
										<label>{{__('email')}}</label>
										<ve-input v-model="auth.email"
										          rules="required|email"></ve-input>
									</ve-form-group>
								</ve-col>
								
								<ve-col xs12
								        sm12>
									<ve-form-group>
										<label>{{__('password')}}</label>
										<input class="form-control"
										       v-model="auth.password"
										       type="password"
										       required/>
									</ve-form-group>
								</ve-col>
								
								<ve-col xs12
								        sm12>
									<ve-form-group>
										<div class="no-match" :class="{ shown: noMatch}">
											{{ __('failedToLogIn') }}
										</div>
										<div class="no-match" :class="{ shown: noMatchCompany}">
											{{ __('failedToFindCompany') }}
										</div>
										<hr>
										<a target="_blank"
										   href="https://app.verified.eu/#/recover-password">{{__('recoverPassword')}}</a>
									</ve-form-group>
								</ve-col>
								
								<ve-col xs12>
									<ve-form-group>
										<button
											type="submit"
											class="btn btn-rounded btn-primary next-btn"
											:class="{loading: busy}"
											:disabled="busy||!auth.email||!auth.password"
											@click.prevent="authenticate(true)"
										>{{ __('authenticate') }}
											<img src="//app.verified.eu/lib/iframes/common/assets/loader64.gif"
											     :style="{ opacity: busy ? 1 : 0 }">
										</button>
									</ve-form-group>
								</ve-col>
							</div>
						</ve-form>
					</div>
					
					<div class="card-body text-center pb-0"
					     v-else>
						<h6 class="mb-4">{{ __('choose_company') }}</h6>
						
						<div class="row"
						     v-for="(c, i) in this.validCompanies"
						     :key="i">
							<ve-col xs12>
								<button type="button"
								        class="btn btn-rounded btn-primary next-btn my-4"
								        :class="{loading: busy && c === company}"
								        :disabled="busy"
								        @click="continueAuthentication(c)">
									{{ c.name }}
								</button>
							</ve-col>
						</div>
					</div>
				</div>
			</ve-col>
		</div>
	</div>
</div>
</template>

<script>
	import axios from 'axios'
	import { auth } from '@verified/libvf'
	import Vue from 'vue'
	import VueCookies from 'vue-cookies'
	
	export default {
		name: "ve-login",
		props: {
			logosrc: {
				type: String
			},
			logowidth: {
				type: String
			},
			authtoken: {
				type: String
			},
			authorizedCompanies: {
				type: Array,
				default: function() { return [] }
			}
		},
		data() {
			return {
				auth: {},
				loggedIn: false,
				translations: null,
				noMatch: false,
				noMatchCompany: false,
				exceptions: [],
				loading: true,
				busy: false,
				user: {},
				company: {},
				validCompanies: []
			}
		},
		watch: {
			exceptions: function(newVal) {
				if(newVal.length)
					this.$emit('exception', newVal[newVal.length - 1])
			}
		},
		methods: {
			// Return translation for given key
			__(key) {
				if(this.translations && this.translations[key])
					return this.translations[key][this.$root.locale]
				return '...'
			},
			getURL() {
				if(location.search) {
					try {
						if(location.search.substring(1, 2) === "&") {
							this.qp = location.search.substring(0, 1) + location.search.substring(2, location.search.length);
							this.qp = JSON.parse("{\"" + decodeURI(qp.substr(1)).replace(/"/g, "\\\"").replace(/&/g, "\",\"").replace(/=/g, "\":\"") + "\"}");
						} else
							this.qp = JSON.parse("{\"" + decodeURI(location.search.substr(1)).replace(/"/g, "\\\"").replace(/&/g, "\",\"").replace(/=/g, "\":\"") + "\"}");
					} catch(e) { console.log(e) }
				}
			},
			// Authentication
			async authenticate(authenticationInProgress) {
				this.noMatch = false;
				this.noMatchCompany = false;
				this.busy = true;
				
				if(authenticationInProgress) {
					try {
						this.token = await auth.login({
							email: this.auth.email,
							password: this.auth.password
						});
						
						const companies = await auth.getCompanies();
						for(let i = 0; i < companies.length; i++)
							if(this.authorizedCompanies.indexOf(companies[i].id) !== -1)
								this.validCompanies.push(companies[i])
						
						
						if(this.validCompanies.length === 0) {
							this.noMatchCompany = true
							this.auth.password = undefined
							this.busy = false
						} else if(this.validCompanies.length === 1) {
							this.company = this.validCompanies[0]
							this.continueAuthentication()
						} else {
							this.busy = false
						}
					} catch(e) {
						this.exceptions.push(new Error("An error occured during call to authenticate"))
						this.busy = false;
						this.noMatch = true;
						this.auth.password = undefined;
					}
				} else {
					if(this.qp && this.qp["document_uid"]) { // Public link, needs autentication
						await auth.useTokenFromUrl();
						// this.initAsEditor();
						console.log("no initAsEditor()")
					} else if($cookies.get("user")) {
						this.user = $cookies.get("user");
						await auth.authenticate({
							token: $cookies.get("token"),
							namespace: this.user.namespace
						});
						this.initAsOwner();
					} else {
						this.busy = false;
						this.loading = false;
					}
				}
			},
			async continueAuthentication(company) {
				if(company)
					this.company = company
				
				this.busy = true
				
				try {
					await auth.setToken(this.token);
					
					const user = await auth.getUserinfo();
					this.user = {
						givenName: user.givenName,
						familyName: user.familyName,
						email: user.email,
						token: this.token,
						company: this.company.name,
						namespace: "/companies/" + this.company.id,
						dashboardLink: "https://app.verified.eu/#/dashboard?companyId=" + this.company.id
					};
					$cookies.set("user", this.user);
					$cookies.set("token", this.token);
					
					await auth.authenticate({
						token: auth.token,
						namespace: this.user.namespace
					});
					this.initAsOwner();
				} catch(e) {
					console.log(e);
					this.exceptions.push(new Error("An error occured during envelope creation"));
					this.busy = false;
				}
			},
			initAsOwner() {
				try {
					this.user.role = auth.role
					this.loggedIn = true
					setTimeout(() => {
						this.$emit('login', this.user);
					}, 500)
				} catch(e) {
					console.log(e);
					$cookies.remove("user");
					$cookies.remove("token");
					this.exceptions.push(new Error("An error occured during envelope creation"));
					this.busy = false;
				}
			}
		},
		async created() {
			Vue.use(VueCookies)
			
			// Get sheets data
			try {
				const [login] = await Promise.all([
					axios.get('https://sheets.web-services.verified.eu/login/translations')
				])
				this.translations = login.data
			} catch(e) {
				this.exceptions.push(new Error("Unable to get sheet data. Try refreshing your browser."))
			}
			
			// Authenticate libvf
			try {
				auth.setToken(this.authtoken)
			} catch(e) {
				this.exceptions.push(e)
			}
			
			this.getURL()
			this.authenticate(false)
			this.loading = false
		}
	};
</script>