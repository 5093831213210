<template>
<div>
	<div class="dropdown">
		<input class="form-control"
		       ref="field"
		       data-toggle="dropdown"
		       aria-haspopup="true"
		       aria-expanded="false"
		       :value="value"
		       :readonly="readonly"
		       :disabled="disabled"
		       @input="handleInput">
		
		<div class="invalid-feedback"
		     v-if="!validation.status">
			{{ __(validation.key, validation.args) }}
		</div>
		
		<ul class="dropdown-menu shadow-sm"
		    role="menu"
		    :style="{ opacity: brreg_list.length }">
			<li v-for="(item, index) in brreg_list"
			    :key="index">
				<a class="dropdown-item"
				   href="#"
				   @click="handleSelect(brreg_list[index])">{{ item }}</a>
			</li>
		</ul>
	</div>
</div>
</template>

<script>
	import { validate } from "../../../node_modules/@verified/veform/src/validations"
	import axios from 'axios'
	
	export default {
		name: "ve-brreg-name",
		props: {
			value: {
				type: String
			},
			items: {
				type: Array,
				default: () => { return [] }
			},
			searchable: {
				type: Boolean,
				default: true
			},
			readonly: {
				type: Boolean,
				default: false
			},
			disabled: {
				type: Boolean,
				default: false
			},
			rules: {
				type: String,
				default: ""
			}
		},
		data() {
			return {
				brreg: "https://data.brreg.no/enhetsregisteret/",
				brreg_all_data: {},
				brreg_list: [],
				validation: { status: true },
				exceptions: []
			}
		},
		methods: {
			handleInput(e) {
				if(!this.$root.searchOngoing) {
					if(!e.srcElement.value) return
					if(e.srcElement.value.length < 3) return
					
					let val = e.srcElement.value
					if(val) {
						this.search(val)
						this.$emit('input', e.srcElement.value)
						this.performValidation(e.srcElement.value)
					} else this.$refs.field.setCustomValidity("Invalid company name")
				} else {
					this.$root.searchOngoing = false
					this.performValidation(e.srcElement.value)
				}
			},
			async search(value) {
				let list = await this.searchName(value)
				this.brreg_all_data = {}
				this.brreg_list = []
				if(!list) {
					this.$refs.field.setCustomValidity("Search didn't return any companies")
				} else if(list.length == 1 && list[0].navn.length == value.length) {
					this.update(list[0]);
					this.$refs.field.setCustomValidity("")
				} else {
					let valid = false
					
					for(let org of list) {
						this.brreg_all_data[org.navn] = org
						this.brreg_list.push(org.navn)
						if(org.navn.length == value.length) {
							this.update(item);
							valid = true;
						}
					}
					this.$refs.field.setCustomValidity("", valid)
				}
			},
			handleSelect(value) {
				for(let name in this.brreg_all_data)
					if(value === name) {
						this.update(this.brreg_all_data[name])
						return
					}
			},
			performValidation(value) {
				this.validation = validate(value, this.rules)
				
				if(this.validation.status) {
					this.$refs.field.setCustomValidity("")
				} else {
					this.$refs.field.setCustomValidity(this.validation.message)
				}
				
			},
			async searchName(name) {
				let link = this.brreg + "enhet.json?page=0&size=10&$filter=startswith%28navn%2C%27" + encodeURIComponent(name) + "%27%29"
				
				try {
					const [brreg] = await Promise.all([
						axios.get(link)
					])
					
					return brreg.data.data
					
				} catch(e) {
					this.exceptions.push(new Error("Unable to get brreg data. Try refreshing your browser."))
				}
			},
			update(company) {
				if(!company) return;
				this.$root.searchOngoing = true
				this.$emit('setBrregSearch', true)
				this.$emit('update', company)
			}
		},
		mounted() {
			this.performValidation(this.value)
		}
	}
</script>
