import axios from 'axios'
import Vue from 'vue'
// import Octicons from '@primer/octicons'
// import '@primer/octicons/build/build.css'
import Radio from '../components/radio/radio'
import Textarea from '../components/textarea/textarea'
import Portal from '../components/portal/portal'
import Search from '../components/search/search'
import Datepicker from 'vuejs-datepicker'
import { nbNO, en } from 'vuejs-datepicker/dist/locale'
import { auth, envelopes } from '@verified/libvf'

//import Health from '../components/forms/health/form-health'
//import Motor from '../components/forms/motor/form-motor'
//import Property from '../components/forms/property/form-property'

export default {
	name: 'app',
	data() {
		return {
			en: en,
			no: nbNO,
			qp: [],
			translations: {},
			configuration: {},
			loading: true,
			submitting: false,
			exceptions: [],
			attachments: {},
			attachments_checked: {},
			hidePortalContent: false,
			contentOpacity: 1,
			formOpacity: 1,
			step: 0,
			searchCompleted: false,
			error: false,
			temp: {},
			user: { namespace: '/r1MZtfWW7' },
			now: new Date(),
			navbarIsOffScreen: false,
			signers: {
				client: null,
				clientGivenName: null,
				clientFamilyName: null,
				clientPosition: null,
				clientEmail: null,
				clients: [],
				clientsDd: [],
				self: null,
				selfPosition: null,
				selfEmail: null,
				selfs: [],
				selfsDd: []
			},
			ud: {
				form: 'auto',
				greeting: null,
				healthContacts: [],
				propertyContacts: [],
				company: {}
			}
		}
	},
	components: {
		Datepicker
	},
	watch: {
		'signers.client': function(newVal) {
			let i = this.signers.clientsDd.indexOf(this.signers.client)
			if(newVal && i !== -1)
				this.signers.clientPosition = this.__(this.signers.clients[i].role) || this.signers.clients[i].role
		},
		'signers.self': function(newVal) {
			let i = this.signers.selfsDd.indexOf(this.signers.self)
			if(newVal && i !== -1) {
				this.signers.selfPosition = this.signers.selfs[i].position
				this.signers.selfEmail = this.signers.selfs[i].email
			}
		}
	},
	methods: {
		// Return translation for given key
		__(key) {
			if(this.translations[key])
				return this.translations[key][this.$root.locale]
		},
		// Return config label for given key and language, or value if no label
		__config(key) {
			if(this.configuration[key])
				if(this.configuration[key][this.$root.locale])
					return this.configuration[key][this.$root.locale]
				else
					return this.configuration[key].value
		},
		colorLuminance(hex, lum) {
			// Validate hex string
			hex = String(hex).replace(/[^0-9a-f]/gi, '')
			if(hex.length < 6)
				hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
			lum = lum || 0;
			
			// Convert to decimal and change luminosity
			let rgb = '#', c, i
			for(i = 0; i < 3; i++) {
				c = parseInt(hex.substr(i * 2, 2), 16)
				c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16)
				rgb += ('00' + c).substr(c.length)
			}
			return rgb
		},
		formSelected(value) {
			//Form or URL (can't have space in URL)
			if(value && value.indexOf(" ") !== -1) {
				this.hidePortalContent = true
				this.ud.form = value.split(" ")[1]
				
				this.initDefaultAttachments()
				
				if(this.ud.form === 'select')
					this.fadeToStep(0)
				else if(this.ud.form === 'search')
					this.fadeToStep(5)
				else
					this.fadeToStep(1)
			} else if(value) {
				window.location.href = value
			}
		},
		fadeToStep(step) {
			this.formOpacity = 0
			setTimeout(() => {
				this.step = step
				if(step === 0)
					this.hidePortalContent = false
				setTimeout(() => {
					this.contentOpacity = this.formOpacity = 1
				}, 10)
			}, 500)
		},
		validateDatepicker(id) {
			var element = document.getElementById(id);
			if(element) {
				var value = element.value
				return value ? true : false
			}
		},
		nextStep() {
			if(this.step === 1) {
				if(!this.$refs.form1.$children[0].$refs.searchForm.validate())
					return
				
				if(!this.searchCompleted) {
					this.error = true
					return
				}
			} else if(!this.$refs[`form${ this.step }`].validate()) {
				return
			} else if(this.step === 3 && !this.validateDatepicker("datepicker-deadline")) { 
				return
			}

			if(this.step < 3)
				this.fadeToStep(this.step + 1)
			else if(this.step === 3)
				this.submit()
		},
		setLanguage(lang) {
			this.$root.locale = lang
			this.setIso(this.$root.locale)
			this.$forceUpdate()
		},
		steps() {
			return [
				{ title: this.__('step_1'), button: this.__('step_1_button') },
				{ title: this.__('step_2') + ' ' + this.__('for') + ' ' + (this.ud.company && this.ud.company.name), button: this.__('step_2_button') },
				{ title: this.__('step_3'), button: this.__('step_3_button') }
			]
		},
		companySelected(company) {
			if(company) {
				this.ud.company = company
				this.signers.clients = []
				this.signers.clientsDd = []
				
				//Signatories
				if(company.aml && company.aml.authorizations && company.aml.authorizations.signatureRights) {
					if(company.aml.authorizations.signatureRights.others
						&& company.aml.authorizations.signatureRights.others.signatures
						&& company.aml.authorizations.signatureRights.others.signatures.length)
						this.signers.clients = company.aml.authorizations.signatureRights.others.signatures
					
					if(company.aml.authorizations.signatureRights.required
						&& company.aml.authorizations.signatureRights.required.length)
						this.signers.clients = this.signers.clients.concat(company.aml.authorizations.signatureRights.required)
				}
				
				this.signers.clients.forEach((el) => {
					//Lowercase and insert , after family name
					let name = el.name.toLowerCase().replace(' ', ', ')
					
					//Capitalize
					this.signers.clientsDd.push(name.replace(/(?:^|\s)\S/g, (a) => { return a.toUpperCase() }))
				})
				
				//Remove duplicates
				this.signers.clientsDd = [...new Set(this.signers.clientsDd)]
				
				this.searchCompleted = true
			}
		},
		openUrl(url) { window.open(url) },
		reload() { location.reload() },
		initDefaultAttachments() {
			Object.values(this.attachments).forEach((atc, i) => {
				this.attachments_checked[i] = atc.default_for_form.includes(this.ud.form)
			})
			this.$forceUpdate()
			
			setTimeout(() => {
				let atcs = document.getElementsByClassName('attachments')
				if(atcs.length)
					try {
						for(let i = 0; i < atcs.length; i++)
							for(let j = 0, nodes = atcs[i].childNodes[0].childNodes; j < nodes.length; j++)
								if(this.attachments_checked[j])
									nodes[j].classList.add('default')
								else
									nodes[j].classList.remove('default')
					} catch(e) {}
			}, 1000)
		},
		async submit() {
			this.ud.fileName = 'Samarbeidsavtale ' + this.ud.company.name + ' ' + this.ud.company.org_no + '.pdf'
			this.ud._isForwarded = true
			this.ud.lang = this.$root.locale
			this.ud.translations = this.translations
			
			this.submitting = true
			
			this.now = new Date()
			this.ud.date = this.now.toLocaleDateString()
			try {
				this.ud.validFromDate = new Date(this.ud.validFrom).toLocaleDateString()
				this.ud.validToDate = new Date(this.ud.validTo).toLocaleDateString()
			} catch(e) {
				console.log(e)
			}
			
			try {
				// Create envelope and set bearer token
				const envelope = await envelopes.create('gjensidige-portal')
				const bearerToken = await envelope.getBearerToken('/flows/simple-public-flow')
				auth.setToken(bearerToken)
				await envelope.reflect()
				
				// Set template data
				await envelope.firstTemplate().setUserData(this.ud)
				await envelope.firstDocument().put({ name: this.ud.fileName });
				
				// Download from sheets then upload attachments
				let atcs = []
				Object.values(this.attachments).forEach((atc, i) => {
					if(this.attachments_checked[i])
						atcs[i] = axios({
							method: 'GET',
							url: location.hostname === 'localhost' ? '/attachments/' + atc.url.split('/').pop() : atc.url,
							responseType: 'blob'
						})
				})
				let blobs = await Promise.all(atcs), uploads = []
				blobs.forEach((blob, i) => {
					if(blob !== undefined) {
						console.log('Uploading', this.attachments[i].label)
						uploads.push(envelope.firstDocument().uploadAttachment(this.attachments[i].label + '.pdf', blob.data))
					}
				})
				await Promise.all(uploads)
				
				// Add recipients
				let recs = []
				if(this.signers.clientsDd.length)
					recs.push(envelope.addRecipient({
						givenName: this.signers.client && this.signers.client.split(',')[1].trim(),
						familyName: this.signers.client && this.signers.client.split(',')[0],
						language: this.$root.locale,
						signingMethod: 'email',
						email: this.signers.clientEmail,
						order: 1,
						role: {
							action: 'sign',
							label: 'Signere',
							name: 'signer'
						}
					}))
				else
					recs.push(envelope.addRecipient({
						givenName: this.signers.clientGivenName,
						familyName: this.signers.clientFamilyName,
						language: this.$root.locale,
						signingMethod: 'email',
						email: this.signers.clientEmail,
						order: 1,
						role: {
							action: 'sign',
							label: 'Signere',
							name: 'signer'
						}
					}))
				
				recs.push(envelope.addRecipient({
					givenName: this.signers.self && this.signers.self.split(',')[1].split(' (')[0].trim(),
					familyName: this.signers.self && this.signers.self.split(',')[0],
					language: this.$root.locale,
					signingMethod: 'email',
					email: this.signers.selfEmail,
					order: 2,
					role: {
						action: 'sign',
						label: 'Signere',
						name: 'signer'
					}
				}))
				await Promise.all(recs)
				
				// Set expiration date
				await envelope.put({ expiration: this.ud.deadline })
				
				// Publish the envelope
				await envelope.publish()
				console.log('Published')
				
				this.fadeToStep(4)
			} catch(e) {
				console.error(e)
				this.exceptions.push(new Error('An error occured during envelope creation'))
			}
		}
	},
	async created() {
		Vue.use(Radio)
		Vue.use(Textarea)
		Vue.use(Portal)
		Vue.use(Search)

		//Init forms
		/*Vue.use(Health)
		Vue.use(Motor)
		Vue.use(Property)*/
		
		this.$root.locale = 'nb_NO'
		this.setIso("nb_NO")
		// this.$root.octicons = Octicons
		
		//Get sheets data
		try {
			let [trans, portal, config, signers, atcs, healthContacts, propertyContacts, brreg, autoPdf, healthPdf, propertyPdf] = await Promise.all([
				axios.get('https://sheets.web-services.verified.eu/gjensidige-portal/translations'),
				axios.get('https://sheets.web-services.verified.eu/gjensidige-portal/portal-translations'),
				axios.get('https://sheets.web-services.verified.eu/gjensidige-portal/config'),
				axios.get('https://sheets.web-services.verified.eu/gjensidige-portal/signers'),
				axios.get('https://sheets.web-services.verified.eu/gjensidige-portal/attachments'),
				axios.get('https://sheets.web-services.verified.eu/gjensidige-portal/health_contacts'),
				axios.get('https://sheets.web-services.verified.eu/gjensidige-portal/property_contacts'),
				axios.get('https://sheets.web-services.verified.eu/brreg-translations/brreg-translations'),
				axios.get('https://sheets.web-services.verified.eu/gjensidige-portal/auto_pdf'),
				axios.get('https://sheets.web-services.verified.eu/gjensidige-portal/health_pdf'),
				axios.get('https://sheets.web-services.verified.eu/gjensidige-portal/property_pdf')
			])
			
			// This combines veform's default translations with the ones from sheets
			// duplicates are overwritten by the sheet
			this.translations = { ...trans.data, ...portal.data }
			this.mergeLocale(trans.data)
			this.mergeLocale({
				"validation.fullname": {
					en_EN: trans.data.validation_fullname && trans.data.validation_fullname.en_EN,
					nb_NO: trans.data.validation_fullname && trans.data.validation_fullname.nb_NO
				}
			})
			this.mergeLocale(brreg.data)
			this.configuration = config.data
			this.signers.selfs = signers.data
			this.attachments = atcs.data
			this.ud.healthContacts = healthContacts.data
			this.ud.propertyContacts = propertyContacts.data
			this.ud.auto_pdf = autoPdf.data
			this.ud.health_pdf = healthPdf.data
			this.ud.property_pdf = propertyPdf.data
			
			//Init signers
			Object.keys(this.signers.selfs).forEach((el) => {
				this.signers.selfsDd.push(this.signers.selfs[el].family_name + ", " + this.signers.selfs[el].given_name + " (" + this.signers.selfs[el].email + ")")
			})
			
			//Set page favicon and title
			if(this.__config('favicon'))
				document.getElementById('favicon').href = this.__config('favicon')
			if(this.__config('title'))
				document.title = this.__config('title')
			
			//Set CSS colors
			if(this.__config('primary_color')) {
				document.documentElement.style.setProperty('--primary', this.__config('primary_color'))
				document.documentElement.style.setProperty('--highlight', this.colorLuminance(this.__config('primary_color'), -0.18))
				document.documentElement.style.setProperty('--secondary', this.__config('secondary_color'))
				document.documentElement.style.setProperty('--navbar', this.__config('navbar_color'))
			}
			
			//Set default greeting
			if(this.__('default_email_greeting'))
				this.ud.greeting = this.__('default_email_greeting')
		} catch(e) {
			console.error(e)
			this.exceptions.push(new Error('Unable to get sheet data. Try refreshing your browser.'))
		}
		
		//Check if navbar is visible on scroll (for sticky stepper)
		window.addEventListener('scroll', () => {
			let elem = document.getElementById('navbar')
			
			if(elem) {
				let rect = elem.getBoundingClientRect()
				this.navbarIsOffScreen = (
					rect.x + rect.width) < 0
					|| (rect.y + rect.height) < 0
					|| (rect.x > window.innerWidth || rect.y > window.innerHeight
					)
				this.$forceUpdate()
			} else {
				this.navbarIsOffScreen = false
			}
		})
		
		this.loading = false
	}
}
//todo mobile-friendly contact tables
//todo correct company name for authorizedCompanies when if gets a verified company
//todo attachments defaults selected

//ve-dropdown show on focus
//ve-dropdown arrow indicator ?